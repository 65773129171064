var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { pegParse } from './src/parser';
import { normalizeHashtagInPlural } from './src/normalize';
export * from './src/types';
export * from './src/parser';
export function parse(input, opts) {
    opts = __assign({ normalizeHashtagInPlural: true, shouldParseSkeleton: true }, (opts || {}));
    var els = pegParse(input, opts);
    if (opts.normalizeHashtagInPlural) {
        normalizeHashtagInPlural(els);
    }
    return els;
}
